.contact-us-popup {
  text-align: center;
  .popup-modal {
    .header {
      border: none;
    }
    .content {
      padding-top: 0;
    }
  }
  .text3 {
    a {
      text-decoration: none;
    }
  }
}
.privacy-us-popup {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  animation-delay: 0.5s;
  text-align: center;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .popup-modal {
    .header {
      border: none;
    }
    .content {
      padding-top: 0;
    }
  }
  .text3 {
    a {
      text-decoration: none;
    }
  }
}

.terms-popup {
  padding: 10px;
  text-align: center;
}
.howtoredeem-us-popup {
  padding: 10px;
  text-align: center;
  .redeem-title {
    text-align: center;
  }
  ol {
    text-align: start;
    padding-left: 0;
    li {
      margin-bottom: 0.5rem;

      ul {
        margin-bottom: 0.5rem;
      }
    }
  }
}
