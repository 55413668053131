.congrats-text {
  color: #CF0510;
  font-size: 28px;
  font-family: Avenir-black;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0;
}

.congratulation-container {
  // background-image: url("../../assets/images/congrats-background.png");
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  margin-top: 1em;
}
.text12 {
  color: #1d1d1b;
  font-family: Avenir-semibold;
  font-weight: 700;
  font-size: 1.1em;
  // margin-bottom: 6em;
  margin-left: 1em;
  margin-right: 1em;
}
.yellow-text {
  color: #1d1d1b;
  font-weight: bold;
  // margin-bottom: 4em;
  font-family: Avenir-black;
  line-height: 19px;
}
.reward-text {
  margin: 0;
  font-family: Avenir-black;
  // font-weight: bold;
  font-size: 24px;
  color: #E61D25;
}
.text14{
  margin: 0;
  font-size: 16px;
}
.sms-text{
  font-size: 14px;
  font-weight: normal;
}
.link-text{
  font-size: 14px;
  color: #CF0510;
}
.footer-img {
  position: absolute;
  bottom: 0;
  left: -50%;
  transform: translateX(50%);
  width: 100%;
}
